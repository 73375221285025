import React,{useState,useEffect} from "react";
import {Helmet} from "react-helmet"
import { useLocation } from "react-router-dom";
import DeviceDetector from "device-detector-js";
const PageHeader = () => {
    const deviceDetector = new DeviceDetector();
    const [defaultZoom,setDefaultZoom]=useState("5.0");
    let location = useLocation();
    useEffect(() => {
        setDefaultZoom(defaultZoom=="5.0"?"1.0":"5.0")
        }, [location.pathname])
    const setpagehead = () => {
        let viewport = document.querySelector("meta[name=viewport]");
        let url = location.pathname;
        let a = deviceDetector.parse(navigator.userAgent)
        if(
            url === "/survey" ||
            url === "/lookfeel" ||
            url === "/account-settings" ||
            url === "/create-survey-templates" ||
            url === "/preview" ||
            url === "/add-questions" ||
            url === "/preview" ||
            url === "/theme" ||
            url === "/plans-list" ||
            url === "/distribution" ||
            url === "/dataanalysis" ||
			url === "/update-profile" ||
			url === "/questions-library" ||
			url === "/client-list" ||
			url === "/subadmin-list" ||	
			url === "/countries-hosted" ||
			url === "/survey-listing" ||
			url === "/scoring" ||
			url === "/translation" ||
			url === "/library" ||
			url === "/contactfolders" ||
			url === "/contactlist" ||
			url === "/contacts" ||
			url === "/settings" ||
			url === "/categories" ||						
            url === "/result"
        ){
            viewport.setAttribute('content', 'width=device-width, shrink-to-fit=no, maximum-scale=1, width=1024');
            
            setTimeout(() => {
                // console.log("222222222222222222",deviceDetector.parse(navigator.userAgent))
                
                if((a.device.brand === "Apple" && a.client.name === "Mobile Safari") || a.client.name === "Safari"){
                    viewport.setAttribute('content', 'width=device-width, shrink-to-fit=no, initial-scale=1, maximum-scale=1, width=1024');
                } else {
                    viewport.setAttribute('content', 'width=device-width, shrink-to-fit=no , maximum-scale=1, width=1024');
                }
            }, 0.1);
            // viewport.setAttribute('content', 'width=device-width,  shrink-to-fit=no, width=1024');
            return null;
        } else {
            if((a.device.brand === "Apple" && a.client.name === "Mobile Safari") || a.client.name === "Safari"){
                viewport.setAttribute('content', 'width=device-width, shrink-to-fit=no, maximum-scale=1, width=1024');
            } else {
            viewport.setAttribute('content', 'width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1');
            }
            return null;
        }
        
    }
// console.log("defaultZoom",defaultZoom)
    return(
        <Helmet>
            {
                setpagehead()
            }
        </Helmet>
    )
}

export default PageHeader;