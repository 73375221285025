import Keycloak from "keycloak-js";

// const keycloak = new Keycloak({
//     url: "https://sso.loompin.com",
//     realm: "LoompinStage",
//     clientId: "stage"
// });

// const keycloak = new Keycloak({
//     url: "https://sso.loompin.com",
//     realm: window.location.hostname.replace(/[^\w\s]/gi, '_'),
//     clientId: window.location.hostname
// });

function keycloak(host) {
    console.log('--host2:', host);
    return new Keycloak({
        url: "https://sso.loompin.com",
        realm: host.replace(/[^\w\s]/gi, '_'),
        clientId: host
    });
}

// function keycloak(host) {
//     console.log('--host2:', host);
//     return new Keycloak({
//         url: "https://sso.loompin.com",
//         realm: "inext_se",
//         clientId: "inext.se"
//     });
// }

function keycloak2(realm, clientId) {
    return new Keycloak({
        url: "https://sso.loompin.com",
        realm: realm,
        clientId: clientId
    });
}

export default keycloak;