import React, { useState, useEffect } from "react";
import IndexRouter from "./routers";
import AuthState from "./context/auth/authState";
import PageHeader from "./components/PageHeadSetting";
import AuthApi from "./api/authApi";
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import Keycloak from './Keycloak';
import { useCookies, Cookies } from "react-cookie";


const keycloakProviderInitConfig = {
  onLoad: 'check-sso',
  checkLoginIframe: false
}

function App() {
  const cookies = new Cookies();
  const [keycloak, setKeycloak] = useState(Keycloak(window.location.hostname))
  const [client, setClient] = useState(window.location.hostname)
  // const [cookies, setCookie] = useCookies(['SurveyToken', 'SurveyUser']);

  useEffect(()=>{
    console.log("this is App 2342");
    updateKeyClock()
  },[cookies.get("ssoclient")])
  
  
  const api = new AuthApi();


  const updateKeyClock = async () => {
    // setKeycloak(Keycloak(window.location.hostname))
    let c = await cookies.get("ssoclient")
    console.log("cookies check 2342 ", c);
    if(c && client !== c){
      setKeycloak(Keycloak(c));
    }
  }


  const onKeycloakEvent = (event, error) => {
    if(event == 'onAuthSuccess') {
      if(keycloak.authenticated) {
        let parsedData = keycloak.tokenParsed;
        // console.log('--iss:', parsedData.iss);
        // console.log('--name:', parsedData.given_name, parsedData.family_name);
        // console.log('--email:', parsedData.email);
        api.AuthorizeSSO(parsedData).then((response) => {
          console.log('response: 090 ', response);
        })
        .catch((error) => {
          console.log(error);
        });
      }
    }
  }

  const onKeycloakTokens = (tokens) => {
    // console.log('tokens', tokens)
  }

  console.log("keycloak 555 ", keycloak);

  return (
    <>
      <PageHeader />
      <ReactKeycloakProvider authClient={keycloak} initConfig={keycloakProviderInitConfig} onEvent={onKeycloakEvent} onTokens={onKeycloakTokens}>
        <AuthState>
          <IndexRouter />
        </AuthState>
      </ReactKeycloakProvider>
    </>
  );
}

export default App;
