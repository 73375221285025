import react, {useState} from "react";
import GlobalContext from "./globalContext";
import { useNavigate, useLocation} from "react-router-dom";

const GlobalState = (props) => {
    let navigation = new useNavigate();
    let location = new useLocation();

    const [outerCollapse, setOuterCollapse] = useState(false);
    const [innerCollapse, setInnerCollapse] = useState(false);
    const [activeProject, setActiveProject] = useState({});
    const [categorylist, setCategorylist] = useState([])
    const [organizationPlanData, setOrganizationPlanData] = useState({});
    const [organizationData, setOrganizationData] = useState({});
    const [activatePlanTab, setActivatePlanTab] = useState(false);
    const updateOuter = () => {
        setOuterCollapse(!outerCollapse)
    }

    const updateInner = () => {
        setInnerCollapse(!innerCollapse)
    }

    const updateActiveProject = ( context ) => {
        setActiveProject(context)
    }

    const updateCategoryList = ( data ) => {
        setCategorylist(data);
    }

    return(
        <GlobalContext.Provider value={
            { 
                outerCollapse, 
                innerCollapse,
                updateInner,
                updateOuter,
                updateActiveProject,
                activeProject,
                categorylist,
                updateCategoryList,
                organizationPlanData, 
                setOrganizationPlanData,
                organizationData, 
                setOrganizationData,
                activatePlanTab, 
                setActivatePlanTab
            }
        } >
            {props.children}
        </GlobalContext.Provider>
    )

}

export default GlobalState;