import react, {useContext, useState} from "react";
import ThemeContext from "./themeContext";
import { useNavigate, useLocation} from "react-router-dom";
import AuthContext from "../auth/authContext";
const ThemeState = (props) => {
    const [activeTheme, setActiveTheme] = useState({});
    const { userDetails}=useContext(AuthContext)
    let status1=`${userDetails.userRole==`SuperAdmin`?`all`:`publish`}`
    const [isUpdateThemeList, setIsUpdateThemeList] = useState({
        status:status1,
        isUpdate:false
    });

    const updateThemeSttings = (data) => {
           setActiveTheme(data);
    }
    const updateDefaultThemes = (status) => {
        setIsUpdateThemeList(status);
    } 
    return(
        <ThemeContext.Provider value={{activeTheme,updateThemeSttings,isUpdateThemeList,updateDefaultThemes}} >
            {props.children}
        </ThemeContext.Provider>
    )
}

export default ThemeState;