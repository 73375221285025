import BaseApi from "./baseApi";

class AuthApi extends BaseApi {
    
    async Register(data) {
        return await this.API(
            {
                url: "/auth/register",
                data: data,
                method: "post"
            }
        )
    }
	async Login(data) {
        return await this.API(
            {
                url: "/auth/login",
                data: data,
                method: "post"
            }
        )
    }

    async AuthorizeSSO(data) {
        return await this.API(
            {
                url: "/sso/authorize",
                data: data,
                method: "post"
            }
        )
    }

    async logout() {
        return await this.API(
            {
                url: "/sso/logout",
                method: 'get'
            }
        )
    }
    
    async resendEmailVerification(data) {
        return await this.API({
            url: "/auth/resendEmailVerification",
            data: data,
            method: "post",
        })
    }

    async verifyEmail(data) {
        return await this.API(
            {
                url: "/auth/verify-email",
                data: data,
                method: "post"
            }
        )
    }
    async ResetPassWord(data){
        return await this.API(
            {
                url:'auth/changePassword',
                data:data,
                method:'post'
            }
        )
    }
    async TakeEmail(data){
        return await this.API(
            {
                url:'auth/forgetPassword',
                data:data,
                method:'post'
            }
        )
    }

    async passwordChangeAfterLogin(data){
        return await this.API(
            {
                url:'auth/update-password',
                data:data,
                method:"post"
            }
        )
    }
    async EditProfile(data){
        return await this.API (
            {
                url:'auth/update-profile',
                data:data,
                method:"post"
            }
         )
    }

    async EditTimeZone(data){
        return await this.API (
            {
                url:'auth/update-timezone',
                data:data,
                method:"post"
            }
         )
    }

    async EditLanguage(data){
        return await this.API (
            {
                url:'auth/update-language',
                data:data,
                method:"post"
            }
         )
    }

    async EditUserEmailDefaults(data){
        return await this.API (
            {
                url:'auth/update-user-email-defaults',
                data:data,
                method:"post"
            }
         )
    }

    async EditUserStatus(data){
        return await this.API (
            {
                url:'auth/update-user-status',
                data:data,
                method:"post"
            }
         )
    }

    async AuthenticateUser(data) {
        return await this.API (
            {
                url:'auth/authenticate-user',
                data:data,
                method:"post"
            }
        )
    }

    async getProfile(){
        return await this.API (
            {
                url:'auth/get-profile',
                method:"get"
            }
         )
    }

    async fileUpload(data) {
        return await this.API (
            {
                url:'auth/file-upload',
                data:data,
                method:"post"
            }
        )
    }

    // =============================================================

    async findOrganizationViaMail(data) {
        return await this.API (
            {
                url: "/sso/find-organization-via-mail",
                data: data,
                method: "post"
            }
        )
    }

    // =============================================================
    
}

export default AuthApi;