import React, { useState, useEffect, useContext, Suspense, useCallback } from "react";
import {
  Route,
  Routes,
  Navigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";

import companyLogo from "../assets/images/auth/loompin-logo.png";
import GlobalState from "../context/global/globalState";
import ThemeState from "../context/theme/themeState";
import AuthContext from "../context/auth/authContext";
import { useCookies, Cookies } from "react-cookie";
import { useKeycloak } from "@react-keycloak/web";
const AuthRouter = React.lazy(() => import("./authRouter"));
const SuperAdmin = React.lazy(() => import("./superAdminRouter"));
const ClientRouter = React.lazy(() => import("./clientRouter"));
const SubAdminRouter = React.lazy(() => import("./subAdminRouter"));
const UserRouter = React.lazy(() => import("./userRouter"));
const SurveyRouter = React.lazy(() => import("./attemptSurveyRouter"));
const PrivateRoute = React.lazy(() => import("./privateRoute"));
const FallbackLoader = () => {
  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid row mt-5">
          <div className="col-12 text-center mt-5">
            <img
              className="responsive"
              src={companyLogo}
              alt=""
            />
          </div>
          <div className="col-12 loader-demo-box">
            <div className="dot-opacity-loader">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const IndexRouter = () => {
  const cookies = new Cookies();
  const [searchParams, setSearchParams] = useSearchParams();
  const setUser = useContext(AuthContext);
  const [userRole, setUserRole] = useState("");
  const { keycloak } = useKeycloak();
  let location = useLocation();
  useEffect(() => {
    if (searchParams.get("id")) {
      sessionStorage.setItem("userSurveyId", searchParams.get("id"));
    }
  }, []);

  const verifySSOUser = useCallback(() => {
    //setUsers(users.filter((user) => user.id !== id));
    //console.log('sso user');
  }, [keycloak.sessionId]);

  const checkfunction = () => {
    let sp = location.pathname.split("/");
    if ("survey/form" === sp[1] + "/" + sp[2]) {
      return "surveyAttempt";
    } else {
      if (!keycloak.authenticated && cookies.get('SurveyUser')) {
        let data = cookies.get('SurveyUser');
        if (data.userRole) {
          return data.userRole;
        } else {
          return "";
        }
      } else if(keycloak.authenticated) {
        verifySSOUser();
        return "employee";
      } else {
        return "";
      }
    }
  };

  const updateuser = (data) => {
    setUserRole(data);
  };

  const logoutuser = async () => {
    // if(keycloak.authenticated) {
    //   //keycloak.logout();
	  // keycloak.logout({ redirectUri: window.location.origin });
    // }
    setUser.update("logout");
    // setUserRole("");
    console.log(cookies.get('SurveyUser'))
  };

  return (
    <React.Fragment>
      <GlobalState>
        <ThemeState>
          {checkfunction() === "SuperAdmin" ? (
            <Suspense fallback={<FallbackLoader />}>
              <SuperAdmin logoutUser={logoutuser} />
            </Suspense>
          ) : checkfunction() === "client" ? (
            <Suspense fallback={<FallbackLoader />}>
              <PrivateRoute>
                <ClientRouter logoutUser={logoutuser} />
              </PrivateRoute>
            </Suspense>
          ) : checkfunction() === "subadmin" ? (
            <Suspense fallback={<FallbackLoader />}>
              <SubAdminRouter logoutUser={logoutuser} />
            </Suspense>
          ) : checkfunction() === "user" ? (
            <Suspense fallback={<FallbackLoader />}>
              <PrivateRoute>
                <UserRouter logoutUser={logoutuser} />
              </PrivateRoute>
            </Suspense>
          ) : checkfunction() === "surveyAttempt" ? (
            <Suspense fallback={<FallbackLoader />}>
              <PrivateRoute>
                <SurveyRouter />
              </PrivateRoute>
            </Suspense>
          ) : checkfunction() === "employee" ? (
            <Suspense fallback={<FallbackLoader />}>
              <PrivateRoute>
                <ClientRouter logoutUser={logoutuser} />
              </PrivateRoute>
            </Suspense>
          ) : (
            <Suspense fallback={<FallbackLoader />}>
              <AuthRouter updatedUser={updateuser} />
            </Suspense>
          )}
        </ThemeState>
      </GlobalState>
    </React.Fragment>
  );
};

export default IndexRouter;
