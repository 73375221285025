import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import {CookiesProvider} from "react-cookie";
import { createBrowserHistory } from "history";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider} from '@react-oauth/google';

// create error  --- import "https://cdn.jsdelivr.net/npm/@popperjs/core@2.10.2/dist/umd/popper.min.js";
import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";
import "./assets/vendors/mdi/css/materialdesignicons.min.css";

import "./assets/vendors/flag-icon-css/css/flag-icon.min.css";
import "./assets/vendors/css/vendor.bundle.base.css";
import "./assets/vendors/jquery-bar-rating/css-stars.css";
import "./assets/vendors/font-awesome/css/font-awesome.min.css";

import "./assets/css/demo_1/style.css";
import "./assets/css/demo_1/bodyScroll.css";

import "./assets/images/favicon.png";
import "bootstrap/dist/js/bootstrap.js";
import "./assets/js/scripts.js";
const Index = () => {
  return (
    <BrowserRouter history={createBrowserHistory()}>
      <CookiesProvider>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <App />
          <ToastContainer position="top-right" autoClose={2000} />
        </GoogleOAuthProvider>
      </CookiesProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
