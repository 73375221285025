import {useState} from "react";
import AuthContext from "./authContext";
import AuthApi from "../../api/authApi";
import { useCookies, Cookies } from "react-cookie";
import { useKeycloak } from "@react-keycloak/web";
import { googleLogout } from '@react-oauth/google';
const AuthState = (props) => {
    const cookies = new Cookies();
    let api = new AuthApi();
    const { keycloak } = useKeycloak();
    const getSurveyUserData = () => {
        let surveyUser = cookies.get('SurveyUser') || null;
        let data = {}
        if(surveyUser) {
            try {
                data = surveyUser;
            } catch(e) {
                console.log('invalid user data');
            }
        }
        return data;
    }

    const [userDetails, setUserData] = useState(getSurveyUserData);
    const update = async (type="login") => {
        console.log("========= check login data 435 =", type)
        console.log("========= check cookies 55585 =", cookies)
        console.log("========= check cookies 55585 =", cookies.getAll())
        console.log("========= check cookies.get('SurveyUser') 435 =", cookies.get('SurveyUser'))
        console.log("========= check cookies.get('SurveyToken') 435 =", cookies.get('SurveyToken'))
        if(type == "login"){
			setUserData(getSurveyUserData());
        } else {


            try {
                const removalPromises = [
                  cookies.remove('SurveyToken', { path: '/' }),
                  cookies.remove('SurveyUser', { path: '/' }),
                  // Remove cookies with dot domain, handling potential errors
                  ...(await Promise.all([
                    cookies.remove('SurveyToken', { path: '/', domain: `.${window.location.hostname}` }),
                    cookies.remove('SurveyUser', { path: '/', domain: `.${window.location.hostname}` }),
                  ]).catch((error) => {
                    // Log or handle errors in removing dot domain cookies
                    console.error('Error removing dot domain cookies:', error);
                    return []; // Return empty array to prevent promise rejection
                  })),
                ];
            
                await Promise.all(removalPromises);
            
                // Additional logout actions (if applicable):
                await googleLogout();
                if(keycloak.authenticated){
                    await keycloak.logout();
                }
            
                setUserData({}); // Clear user data
                console.log('Logout successful');
                return true
            } catch (error) {
                console.error('Logout error:', error);
                return false
            }

        }
    }
   
    return(
        <AuthContext.Provider value={{update, userDetails }} >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthState;